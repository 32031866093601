@tailwind components;

@layer components{

.container-fluid {
    @apply mx-auto max-w-screen-4xl;
} 

.row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }  

  .faq_page_heading{
    @apply text-[30px] md:text-[34px] lg:text-[34px] font-suzukiBold uppercase text-[#171C8F] leading-[41px] md:leading-[41px] mb-[40px] md:mb-[80px] lg:mb-[80px];
  }

  .faq_grid_heading{
    @apply text-[20px] md:text-[30px] lg:text-[30px] uppercase font-suzukiBold bg-[#001151] text-[#fff] py-[5px] px-[8px] md:px-[30px] lg:px-[30px] mb-[24px]
  }

  .select_wrap{
    @apply text-center mt-[32px] md:mt-[40px] lg:mt-[40px]
  }

  .faq_page_select{
    @apply text-center inline-block relative cursor-pointer
  }

  .select_tag{
    @apply appearance-none w-[220px] outline-none uppercase text-[14px] text-[#001151] font-suzukiBold py-[12px] px-[20px] pl-0 rounded-[2px] bg-transparent border-[2px] border-[#001151]
  }

  .faq_dropdown{
    @apply absolute top-[46%] translate-y-[-50%] right-5 z-[1]
  }

  /* .faq_grid_count{
    @apply w-[15%] md:w-[6%] lg:w-[6%] flex justify-center items-center mb-0 border-r-[#D0D0D1] border-r-[1px] text-[14px] md:text-[18px] lg:text-[18px]
  } */

  .faq_grid_count{
    @apply w-[15%] md:w-[6%] lg:w-[6%] flex justify-center items-center mb-0 text-[14px] md:text-[18px] lg:text-[18px]
  }

  .faq_grid_heads{
    @apply mb-0 w-[73%] md:w-[86%] lg:w-[86%]  items-center py-[16px] px-[16px] text-[14px] md:py-[25px] md:px-[36px] lg:py-[25px] lg:px-[36px] md:text-[18px] lg:text-[18px]
  }

  .faq_grid_signs{
    @apply pr-[12px] md:pr-0 lg:pr-0  w-[11%] md:w-[7%] lg:w-[7%] flex items-center justify-center border-0 cursor-pointer
  }

  .faq_grid_desc{
    @apply md:w-[87%] lg:w-[87%] md:mx-auto lg:mx-auto px-[16px] md:px-[36px] lg:px-[36px] mb-0 text-[14px] md:text-[18px] lg:text-[18px] text-[#000000] leading-[21px] font-suzukiRegular
  }

  .faq_grid_box{
    @apply mb-[56px] md:mb-[120px] lg:mb-[120px]
  }
  
}
