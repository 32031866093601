@tailwind components;

@layer components{

.container-fluid {
    @apply mx-auto max-w-screen-4xl;
}     

.row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }  

.news_blog_desc_wrapper{
    @apply max-w-[880px] mx-auto text-center mb-[60px] 
}

.news_blog_heading{
    @apply lg:leading-[39px] lg:text-[24px]
}

/* .blog_grid{
    @apply lg:w-[49%] w-full mb-[50px]
} */

.blog_grid{
  @apply mr-[40px];
}

/* .blog_grid_img{
    @apply lg:mb-[33px] mb-[18px] relative block lg:w-[600px] lg:h-[420px] h-[240px]
} */


.blog_grid_img{
  @apply lg:mb-[33px] mb-[18px] relative block
}

.blog_content_heading{
    @apply mb-[16px] text-[24px] md:text-[30px] lg:text-[30px] md:leading-[37px] lg:leading-[37px] leading-[30px] font-suzukiBold text-defaut-color
}

/* .blog_btn{
    @apply hidden lg:block md:block
} */

.news_blog_updates_grid{
    @apply lg:w-[32%] w-full mb-[25px] md:mb-[25px]
}

.news_content_area{
    @apply h-[110px]
}

.news_content_heading{
    @apply text-[22px] lg:text-[22px]
}

.car_blog_grid{
    @apply mx-2
}

.car_blog_img{
    @apply block relative w-full h-[165px] mb-[16px]
}

.car_blog_descript{
    @apply min-h-[120px]
}

.car_blog_content_heads{
    @apply lg:text-[22px] lg:leading-[27px] md:h-[55px] lg:h-[55px]
}

.news_blog_desc{
    @apply container-fluid mb-[95px]
} 

.news_blog_tabber{
    @apply container-fluid lg:mb-[90px] mb-[50px]
}

.news_blog_updates{
    @apply container-fluid mb-[60px]
}

.car_blog_section{
    @apply container-fluid mb-[100px]
}

.blog_banner{
    @apply container-fluid
} 

}