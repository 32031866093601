@tailwind components;

@layer components{
    
.container-fluid {
    @apply mx-auto max-w-screen-4xl;
} 

.row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }  

.banner_image{
    @apply relative mb-[95px]
}

.banner{
    @apply relative flex max-w-[1920px] mx-auto
}

.bannerImage{
    @apply object-cover h-full
}

.banner_overlay{ @apply absolute content-[''] z-[1] left-0 right-0 top-0 bottom-0 bg-[rgba(0,0,0,0.3)] max-w-[1920px] mx-auto }

/* .bannerConts{
    @apply container-fluid absolute top-[80%] md:top-[50%] z-[1] md:translate-y-[-50%] left-0 right-0
} */

.bannerConts{
  @apply container-fluid absolute bottom-[60px] z-[1] left-0 right-0
}

.banner_content_head{
    @apply text-[30px] leading-[41px] md:text-[60px] md:leading-[62px] text-[#FFFFFF] uppercase md:max-w-[70%] lg:max-w-[50%]
}
.no_uppercase {
  text-transform: none !important;
}
/* .finance_grid_content{
    @apply md:col_6 col_12 relative items-center p-8 md:pl-8
}  */

.finance_grid_content{
  @apply md:col_6 col_12 relative items-center p-8 md:pl-8 md:flex md:justify-center md:items-center
} 

/* .finance_grid_content_inner{
    @apply max-w-[100%] md:w-[90%] lg:w-[90%] md:absolute lg:absolute md:top-[50%] lg:top-[50%] md:translate-y-[-50%] lg:translate-y-[-50%]
} */

.finance_grid_content_inner{
  @apply max-w-[100%] md:w-[90%] lg:w-[90%]
}

.finance_suzuki_logo{
    @apply mb-5 lg:inline-flex items-center py-2 px-5 border-2 border-[#29366B] border-solid rounded-md
}

.logo_label{
    @apply  text-[#001151] ml-2 align-middle inline-block uppercase 
}

.finance_suzuki_desc_head{
    @apply text-[#001151] lg:text-[24px] lg:leading-[31px] mb-3 uppercase
}

.finance_descs{
  @apply font-suzukiRegular text-defaut-color text-default;
}
}