@tailwind components;

@layer components{

.container-fluid {
    @apply mx-auto max-w-screen-4xl;
} 

.row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }  

.error_page_banner {
    @apply relative
}

.error_page_img{
    @apply relative block max-w-[1920px] lg:h-[700px] h-[580px] mx-auto
}

.error_page_content{
    @apply absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] lg:text-center md:text-center text-left lg:max-w-[320px] w-[70%]
}

.error_page_head{
    @apply lg:text-[162px] text-[92px] leading-[113px] text-[#fff] lg:leading-[200px]
}

.error_page_desc{
    @apply text-[24px] text-[#fff] leading-[30px] mb-[40px]
}

.error_page_btn{
    @apply font-suzukiBold text-[#001151] bg-[#FFFFFF] rounded-[90px] py-[16px] text-[13px] uppercase px-[40px]
}

}