@tailwind components;

@layer components{

.container-fluid {
    @apply mx-auto max-w-screen-4xl;
}

.row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }

  .contact_details_grid{
    @apply container-fluid mb-[80px] md:mb-[60px] lg:mb-[60px] md:pr-[40px];
  }

  .navigate_icon{
    @apply relative block text-center w-[14%]; 
  }

  .contact_details_grid_wrapper{
    @apply row lg:gap-[60px] md:gap-[20px];
  }

  .navigate_icon:after {
    position: absolute;
    content: '';
    border: solid 1px transparent;
    bottom: -7px;
    background: #001151;
    right: 0;
    left: 0;
    width: 40px;
}




}