@tailwind components;

@layer components{

.container-fluid {
    @apply mx-auto max-w-screen-4xl;
} 

.row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }  

  .fleet_slider{
    @apply container-fluid mb-[80px] md:mb-[150px] lg:mb-[150px];
  }

  .models_range{
    @apply container-fluid mb-[80px] md:mb-[150px] lg:mb-[150px]
  }

  .models_range_head{
    @apply uppercase text-primary-blue mb-[65px]
  }

  .fleet_magzine{
    @apply container-fluid mb-[80px] md:mb-[150px] lg:mb-[150px]
  }

  .fleet_heading{
    @apply text-primary-blue mb-[16px] md:mb-[40px] lg:mb-[40px]
  }

  .fleet_priceList{
    @apply container-fluid mb-[80px] md:mb-[150px] lg:mb-[150px]
  }

  .fleetprice_surchr{
    @apply mb-[30px] md:mb-[48px] lg:mb-[48px] text-[16px] leading-[24px] font-suzukiBold text-[#828282] capitalize
  }

  .fleet_surchrgeamnt{
    @apply block md:inline-block lg:inline-block font-suzukiBold text-[16px] leading-[24px] capitalize text-[#003690]
  }

  .fleetprice_chargeinfo{
    @apply max-w-[80%] md:max-w-[65%] lg:max-w-[65%] md:pl-[25px] lg:pl-[25px] pl-[15px]
  }

  .fleet_discount{
    @apply text-[#DF003A] text-small mb-[15px]
  }

  .fleet_mysuz{
    @apply inline-block text-[#00368F] text-[16px] pl-[2px]
  }

}