@tailwind components;

@layer components {
  .container-fluid {
    @apply mx-auto max-w-screen-4xl;
  }

  .row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }

  .get__quote__container {
    @apply w-full md:w-full lg:w-[90%] md:mx-auto md:pr-[50px];
  }

  div.get__quote__container label {
    @apply text-[14px] leading-[21px] font-[400]  tracking-[0.02em] font-['SuzukiRegular'] text-[#000];
  }

  .input:focus {
    border-color: #001151;
  }

  div.get__quote__container select {
    @apply font-suzukiRegular;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 15px;
    /* background-image: url("../../public/Vector.png"); */
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    background-size: 10px;
  }

  .customer__info {
    @apply mb-[65px];
  }

  .preffered__option {
    @apply mb-[65px];
  }

  .preffered__dealer {
    @apply mb-[65px];
  }

  .finance__option {
    @apply mb-[10px];
  }

  div.get__quote__container .customer__info input,
  div.get__quote__container .customer__info select {
    @apply w-[100%] h-[50px] rounded-none outline-none text-[14px];
  }

  /* div.get__quote__container .preffered__option input,
    div.get__quote__container .preffered__option select {
      @apply h-[48px] text-[14px] outline-none;
    }

    div.get__quote__container .preffered__option select {
      @apply pr-[30px] h-[46px];
    } */
  div.get__quote__container .preffered__option .grouping__border {
    @apply w-[100%] border-[1px] border-[#C0C0C0] relative;
  }

  div.get__quote__container .preffered__option .grouping__border1 {
    @apply w-[100%] h-[50px] rounded-none outline-none  text-[14px] border-[1px] border-[#C0C0C0];
  }

  div.get__quote__container .preffered__dealer input,
  div.get__quote__container .preffered__dealer select {
    @apply w-[100%] h-[50px] rounded-none outline-none  text-[14px] border-[1px] border-[#C0C0C0];
  }

  div.get__quote__container .finance__option input,
  div.get__quote__container .finance__option select {
    @apply w-[100%] h-[50px] rounded-none outline-none  text-[14px] border-[1px] border-[#C0C0C0];
  }

  div.get__quote__container .submit {
    @apply text-[#fff] border-[1px] mb-[100px] border-[#001151] w-[171px] h-[40px] rounded-[90px] bg-[#001151] text-[13px];
  }

  .banner_image {
    @apply relative mb-[95px];
  }

  .banner {
    @apply relative block h-[480px] max-w-[1920px] mx-auto;
  }

  .bannerImage {
    @apply object-cover h-full;
  }

  .bannerConts {
    @apply container-fluid absolute top-[80%] md:top-[50%] md:translate-y-[-50%] left-0 right-0;
  }

  .banner_content_head {
    @apply text-[30px] md:text-[50px] md:leading-[62px] text-[#FFFFFF] uppercase;
  }

  div label.policy_label {
    @apply text-default text-primary-blue font-suzukiBold inline-block;
  }
}
